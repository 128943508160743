import React from 'react';
import PropTypes from 'prop-types';
import styles from './home.wrapper.scss';

const HomeLayoutWrapper = ({ children, viewMode }) => (
  <div
    className={styles.container}
    data-view-mode={viewMode}
    role="presentation"
  >
    {children}
  </div>
);

HomeLayoutWrapper.propTypes = {
  children: PropTypes.node,
  viewMode: PropTypes.string,
};

export default HomeLayoutWrapper;
