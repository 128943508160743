import { browserLocalStorage } from '@wix/wix-vod-shared/dist/src/common/utils/local-storage';
import { DEAL_INFO_TYPES } from '@wix/wix-vod-constants/dist/common/deal-info-types';

import { VIDEO_PAYMENT_LOGOUT_KEY } from '../constants/payments';
import { getWixSDK } from './wix-sdk';

export const openPaymentModalAfterReload = (paymentType) => {
  browserLocalStorage.setItem(
    VIDEO_PAYMENT_LOGOUT_KEY,
    JSON.stringify({
      paymentType,
      openerCompId: getWixSDK().Utils.getOrigCompId(),
    }),
  );
};

export const handleLogOutPaymentModal = ({
  onPurchase,
  onSubscription,
  onRent,
}) => {
  const stored = browserLocalStorage.getItem(VIDEO_PAYMENT_LOGOUT_KEY);

  const openers = {
    [DEAL_INFO_TYPES.RENT]: onRent,
    [DEAL_INFO_TYPES.SALE]: onPurchase,
    [DEAL_INFO_TYPES.SUBSCRIPTION]: onSubscription,
  };

  if (stored) {
    const { paymentType, openerCompId } = JSON.parse(stored);

    if (openerCompId === getWixSDK().Utils.getCompId()) {
      openers[paymentType]();
      browserLocalStorage.removeItem(VIDEO_PAYMENT_LOGOUT_KEY);
    }
  }
};
