import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { hasVideos } from '../../../utils/channel-helpers';
import { isSingleVideo } from '../../../selectors/app-settings';
import { sendBiEvent } from '../../../bi/send-bi-event';
import { RoundedIcon } from '../../icon/icon';
import { handleLogOutPaymentModal } from '../../../utils/handle-logout-payment-modal';

import {
  getTitleText,
  getSubscribeButtonText,
  getPublisherText,
  getPlayButtonText,
  getPurchaseButtonText,
  getOpacityStyle,
} from '../selectors';
import { openSubscription } from '../../../redux/player-overlay/actions/open-subscription';
import { openPlayerOverlay } from '../../../redux/player-overlay/actions/open-player-overlay';
import { openPurchase } from '../../../redux/player-overlay/actions/open-purchase';
import LiveLabel from '../../../containers/live-label/live-label';

import Title from '../partials/title';
import ComingSoon from '../partials/coming-soon';

import styles from './strip.scss';

const mapStateToProps = (state, ownProps) => ({
  isSingleVideo: isSingleVideo(state),
  titleText: getTitleText(state, ownProps),
  subscribeButtonText: getSubscribeButtonText(state, ownProps),
  publisherText: getPublisherText(state, ownProps),
  playButtonText: getPlayButtonText(state, ownProps),
  purchaseButtonText: getPurchaseButtonText(state, ownProps),
  opacityStyle: getOpacityStyle(state),
});

const mapDispatchToProps = {
  openSubscription,
  openPlayerOverlay,
  openPurchase,
  sendBiEvent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class OverlayStrip extends React.Component {
    static propTypes = {
      buttonsWrapperClassName: PropTypes.string,
      isSingleVideo: PropTypes.bool,
      currentSiteUser: PropTypes.object,
      videoItem: PropTypes.object,
      channelData: PropTypes.object,
      isContentFocusable: PropTypes.bool,
      className: PropTypes.string,

      titleText: PropTypes.node,
      publisherText: PropTypes.node,
      subscribeButtonText: PropTypes.node,
      playButtonText: PropTypes.node,
      purchaseButtonText: PropTypes.node,
      opacityStyle: PropTypes.object.isRequired,

      onPlaySelectedVideo: PropTypes.func,

      openSubscription: PropTypes.func.isRequired,
      openPurchase: PropTypes.func.isRequired,
      openPlayerOverlay: PropTypes.func.isRequired,
    };

    static defaultProps = {
      isContentFocusable: true,
    };

    componentDidMount() {
      const { subscribeButtonText } = this.props;

      if (subscribeButtonText) {
        this.props.sendBiEvent('widget.subscription.displayed', {
          whereDisplayed: 'cover',
        });
      }

      handleLogOutPaymentModal({
        onPurchase: this.handlePurchaseButtonClick,
        onSubscription: this.handleSubscriptionButtonClick,
      });
    }

    handlePlaySelectedVideo = (event) => {
      const { videoItem, onPlaySelectedVideo } = this.props;

      event.preventDefault();
      event.stopPropagation();

      onPlaySelectedVideo(videoItem);
    };

    renderPublisher() {
      const styleName = classnames(
        styles.publisher,
        'qa-widget-overlay-publisher',
      );

      return (
        <div className={styleName} data-hook="overlay-publisher">
          {this.props.publisherText}
        </div>
      );
    }

    renderLiveLabel() {
      const { videoItem } = this.props;

      return (
        <LiveLabel
          itemType={videoItem.itemType}
          liveVideoStatus={videoItem.liveVideoStatus}
          videoTitle={videoItem.title}
          startTime={videoItem.dateStartLive}
          className={styles['live-label']}
        />
      );
    }

    renderPlayButton() {
      const { channelData, isContentFocusable, isSingleVideo, playButtonText } =
        this.props;

      if (!hasVideos(channelData) && !isSingleVideo) {
        return <ComingSoon className={styles['soon-info']} />;
      }

      if (!playButtonText) {
        return null;
      }

      const styleName = classnames(styles.button, styles['play-button']);
      const iconStyleName = classnames(styles.icon, styles['play-icon']);

      return (
        <a
          href=""
          className={styleName}
          data-hook="overlay-play-button"
          onClick={this.handlePlaySelectedVideo}
          tabIndex={isContentFocusable ? 0 : -1}
        >
          <RoundedIcon className={iconStyleName} name="arrow-right-filled" />
          <span className={styles.text}>{playButtonText}</span>
        </a>
      );
    }

    handlePurchaseButtonClick = (event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      const { videoItem, openPlayerOverlay } = this.props;

      openPlayerOverlay(videoItem);
    };

    renderPurchaseButton() {
      const { purchaseButtonText } = this.props;

      if (!purchaseButtonText) {
        return null;
      }

      const styleName = classnames(
        'qa-widget-overlay-buy-from-button',
        styles.button,
      );

      return (
        <a
          href=""
          className={styleName}
          onClick={this.handlePurchaseButtonClick}
          data-hook="paid-access-button"
          tabIndex={this.props.isContentFocusable ? 0 : -1}
        >
          <span className={styles.text}>{purchaseButtonText}</span>
        </a>
      );
    }

    handleSubscriptionButtonClick = (event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      this.props.openSubscription();
    };

    renderSubscribeButton() {
      const { subscribeButtonText, isContentFocusable } = this.props;

      if (!subscribeButtonText) {
        return null;
      }

      const styleName = classnames(
        styles.button,
        'qa-widget-overlay-subscribe-button',
      );

      return (
        <a
          href=""
          className={styleName}
          data-hook="subscribe-button"
          onClick={this.handleSubscriptionButtonClick}
          tabIndex={isContentFocusable ? 0 : -1}
        >
          <span className={styles.text}>{subscribeButtonText}</span>
        </a>
      );
    }

    renderButtons() {
      const { buttonsWrapperClassName } = this.props;
      const styleName = classnames(
        styles['buttons-wrapper'],
        buttonsWrapperClassName,
      );

      return (
        <div className={styleName}>
          {this.renderPlayButton()}
          {this.renderSubscribeButton() || this.renderPurchaseButton()}
        </div>
      );
    }

    render() {
      const {
        channelData,
        videoItem,
        className,
        titleText,
        publisherText,
        opacityStyle,
      } = this.props;

      if (!channelData) {
        return null;
      }

      const styleName = classnames(className, styles.overlay);

      return (
        <div
          style={opacityStyle}
          className={styleName}
          data-hook="player-overlay player-overlay-visible"
          onClick={this.handlePlaySelectedVideo}
        >
          <div className={styles.content}>
            <div className={styles['top-content']}>
              {publisherText && this.renderPublisher()}

              {titleText && (
                <div className={styles.title}>
                  <Title text={titleText} />
                </div>
              )}

              {videoItem && this.renderButtons()}
              {this.renderLiveLabel()}
            </div>
          </div>
        </div>
      );
    }
  },
);
