import { createAction } from 'redux-actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getAllSettings } from '../../../selectors/app-settings';
import { canShowChannelCover } from '../../../components/player-overlay/selectors';
import { notForPreview } from '../../../utils/not-for-preview';
import { openFullScreenPurchaseOverlay } from '../../../utils/open-overlay';
import { logPurchaseButtonClicked } from '../../../components/player-overlay/bi';
import { createProxy } from '../../../worker/lib';

const OPEN_PURCHASE = 'CLIENT.PLAYER_OVERLAY.OPEN_PURCHASE';
const openPurchaseAction = createAction(OPEN_PURCHASE);

export const openPurchase = createProxy(
  'openPurchase',
  (videoItem, showChannelCover) => (dispatch, getState) => {
    const state = getState();
    const channelData = getChannelForWidget(state);
    const appSettings = getAllSettings(state);
    const isChannelCover = canShowChannelCover(state, { showChannelCover });

    notForPreview(() => {
      dispatch(
        logPurchaseButtonClicked({
          appSettings,
          videoItem,
          channelData,
          isChannelCover,
        }),
      );
      dispatch(openFullScreenPurchaseOverlay(channelData.id, videoItem.id));
    })();

    dispatch(openPurchaseAction());
  },
);
