import React from 'react';
import _ from 'lodash';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import getScrollParent from '../../utils/get-scroll-parent';

import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import invertSide from '../../utils/popout/invert-side';
import { byTriangleSide } from '../../utils/popout/calculate-position';

import { sides, popoutPositions } from '../../constants/popout';

import dropdownOptionsShape from '../../shapes/dropdown-options';

import Icon from '../icon/icon';
import Popout, { showPopout } from '../popout/popout';
import { WidgetScrollbarWrapper } from '../scrollbar-wrapper/scrollbar-wrapper';

import KEY_CODES from '@wix/wix-vod-shared/dist/src/widget/constants/key-codes';

import styles from './dropdown.scss';

class Option extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    label: PropTypes.node.isRequired,
    value: PropTypes.any.isRequired,

    selected: PropTypes.bool.isRequired,
    isFocusable: PropTypes.bool.isRequired,

    onClick: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    containerRef: PropTypes.func,
  };

  render() {
    const {
      selected,
      className,
      label,
      height,
      containerRef,
      onClick,
      onKeyDown,
      isFocusable,
    } = this.props;
    const classNames = classnames(styles.option, className, {
      selected,
    });

    return (
      <section
        className={classNames}
        ref={containerRef}
        data-hook="dropdown-option"
        title={label}
        onClick={onClick}
        onKeyDown={onKeyDown}
        style={{ height }}
        role="option"
        tabIndex={isFocusable ? 0 : -1}
      >
        {label}
      </section>
    );
  }
}

class BaseDropdown extends React.Component {
  static propTypes = {
    value: PropTypes.any,
    options: dropdownOptionsShape,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onOpen: PropTypes.func,
    rounded: PropTypes.bool,
    className: PropTypes.string,
    dataHook: PropTypes.string,
    ariaLabel: PropTypes.string,
    labelClassName: PropTypes.string,
    triangleClassName: PropTypes.string,
    optionsClassName: PropTypes.string,
    optionClassName: PropTypes.string,
    selectedOptionClassName: PropTypes.string,
    popoutTriangleSide: PropTypes.string,
    popoutPosition: PropTypes.string,
    height: PropTypes.number,
    disabled: PropTypes.bool,
    isFocusable: PropTypes.bool,
    maxOptionsVisible: PropTypes.number,
    enablePopoutPositioningUpdate: PropTypes.bool,
    ScrollbarWrapper: PropTypes.oneOf([WidgetScrollbarWrapper]),
  };

  static defaultProps = {
    height: 32,
    maxOptionsVisible: 5,
    onChange: _.noop,
    onOpen: _.noop,
    rounded: false,
    disabled: false,
    isFocusable: true,
    popoutTriangleSide: sides.NONE,
    popoutPosition: popoutPositions.LEFT,
    enablePopoutPositioningUpdate: false,
    ScrollbarWrapper: WidgetScrollbarWrapper,
  };

  state = {
    isOpen: false,
    isPopoutHidden: true,
  };

  componentDidUpdate() {
    this.updatePopoutPosition();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  wrapperRef = null;
  selectedOptionRef = null;
  triangleRef = null;
  popoutRef = null;

  saveRef = (name, ref) => {
    this[name] = ref;
  };

  calculatePosition() {
    const { popoutTriangleSide: triangleSide, popoutPosition } = this.props;
    return byTriangleSide(this.triangleRef, this.popoutRef, {
      triangleSide,
      popoutPosition,
    });
  }

  updatePopoutPosition() {
    if (!this.props.enablePopoutPositioningUpdate) {
      return;
    }

    const wrapperRect = this.wrapperRef.getBoundingClientRect();

    // getting scroll parent twice, becase first is not enough
    const { scrollLeft } = getScrollParent(getScrollParent(this.wrapperRef));

    let { left, top, offsetX } = this.calculatePosition();
    left -= scrollLeft + wrapperRect.left - offsetX;
    top -= wrapperRect.top;

    this.popoutRef.style.left = `${left}px`;
    this.popoutRef.style.top = `${top}px`;
  }

  close = () => {
    if (this.state.isOpen) {
      this.setOpenState(false);
    }
  };

  toggle = (event) => {
    event.nativeEvent.stopImmediatePropagation();
    this.setOpenState(!this.state.isOpen);
  };

  setOpenState(isOpen) {
    if (isOpen) {
      this.setState({
        isOpen,
        isPopoutHidden: false,
      });

      this.props.onOpen();
      showPopout(this.popoutRef);
    } else {
      this.setState({
        isOpen,
      });

      this.timeout = setTimeout(() => {
        this.setState({
          isPopoutHidden: true,
        });
      }, 200);
    }
  }

  closeAndCallOnChange = (value) => {
    const { onChange } = this.props;
    const didValueChange = value !== this.props.value;

    this.setOpenState(false);
    if (didValueChange) {
      onChange(value);
    }
  };

  openFromKeyboard(event) {
    if (
      _.includes(
        [KEY_CODES.DOWN, KEY_CODES.ENTER, KEY_CODES.SPACE],
        event.keyCode,
      )
    ) {
      event.stopPropagation();

      this.setOpenState(true);
      if (this.selectedOptionRef) {
        _.delay(() => this.selectedOptionRef.focus());
      }
    }
  }

  closeFromKeyboard(event) {
    if (event.keyCode === KEY_CODES.ESC) {
      event.stopPropagation();

      this.close();
      if (this.wrapperRef) {
        _.delay(() => this.wrapperRef.focus());
      }
    }

    if (event.keyCode === KEY_CODES.TAB) {
      event.stopPropagation();

      this.close();
    }
  }

  handleWrapperKeyDown = (event) => {
    this.openFromKeyboard(event);
  };

  navigateByOptionsFromKeyboard(event) {
    if (event.keyCode === KEY_CODES.DOWN && event.target.nextElementSibling) {
      event.stopPropagation();
      event.target.nextElementSibling.focus();
    }

    if (event.keyCode === KEY_CODES.UP && event.target.previousElementSibling) {
      event.stopPropagation();
      event.target.previousElementSibling.focus();
    }
  }

  selectOptionFromKeyboard(event, value) {
    if (_.includes([KEY_CODES.ENTER, KEY_CODES.SPACE], event.keyCode)) {
      event.stopPropagation();

      this.closeAndCallOnChange(value);
      if (this.wrapperRef) {
        _.delay(() => this.wrapperRef.focus());
      }
    }
  }

  handleOptionKeyDown = (value, event) => {
    this.navigateByOptionsFromKeyboard(event);
    this.selectOptionFromKeyboard(event, value);
    this.closeFromKeyboard(event);
  };

  renderSelectedValue() {
    const { options, value, placeholder } = this.props;
    const selectedOption = _.find(options, (option) =>
      _.isEqual(option.value, value),
    );

    if (selectedOption) {
      return (
        <span className={styles.value} title={selectedOption.label}>
          {selectedOption.label}
        </span>
      );
    }

    return (
      <span className={styles.value}>
        <span className={styles.placeholder}>{placeholder}</span>
      </span>
    );
  }

  renderOptions() {
    const {
      options,
      value,
      height,
      optionClassName,
      selectedOptionClassName,
      maxOptionsVisible,
      ScrollbarWrapper,
    } = this.props;

    const renderedOptions = _.map(options, (option, i) => {
      if (option.categoryLabel) {
        return (
          <div
            key={`category-${option.categoryLabel}`}
            className={styles.category}
          >
            {option.categoryLabel}
          </div>
        );
      }

      const isSelected = _.isEqual(value, option.value);
      const classNames = classnames(optionClassName, {
        [selectedOptionClassName]: selectedOptionClassName && isSelected,
      });

      return (
        <Option
          key={i}
          label={option.label}
          value={option.value}
          selected={isSelected}
          className={classNames}
          height={height}
          containerRef={
            isSelected
              ? memoizedPartial(this.saveRef, 'selectedOptionRef')
              : null
          }
          isFocusable={this.state.isOpen}
          onKeyDown={memoizedPartial(this.handleOptionKeyDown, option.value)}
          onClick={memoizedPartial(this.closeAndCallOnChange, option.value)}
        />
      );
    });

    if (options.length > maxOptionsVisible) {
      return <ScrollbarWrapper>{renderedOptions}</ScrollbarWrapper>;
    }
    return renderedOptions;
  }

  render() {
    const {
      rounded,
      disabled,
      popoutTriangleSide,
      popoutPosition,
      options,
      className,
      optionsClassName,
      labelClassName,
      triangleClassName,
      height,
      maxOptionsVisible,
      enablePopoutPositioningUpdate,
      dataHook,
      ariaLabel,
      isFocusable,
    } = this.props;
    const { isOpen, isPopoutHidden } = this.state;

    const classNames = classnames(styles.dropdown, className, {
      [styles.open]: isOpen,
      [styles['hide-popout']]: isPopoutHidden,
      [styles.rounded]: rounded,
      [styles.disabled]: disabled,
    });

    const popoutHeight =
      options.length > maxOptionsVisible ? height * maxOptionsVisible : 0;
    const stickClassNames = enablePopoutPositioningUpdate
      ? {}
      : {
          [styles['stick-to-left']]: popoutPosition === popoutPositions.LEFT,
          [styles['stick-to-center']]:
            popoutPosition === popoutPositions.CENTER,
          [styles['stick-to-right']]: popoutPosition === popoutPositions.RIGHT,
          [styles['stick-to-bottom']]: popoutPosition === popoutPositions.TOP,
        };

    const optionsClassNames = classnames(styles.options, optionsClassName, {
      [styles.single]: options.length === 1,
      ...stickClassNames,
    });
    const tabIndex = !isFocusable || isOpen || !isPopoutHidden ? -1 : 0;

    return (
      <section
        ref={memoizedPartial(this.saveRef, 'wrapperRef')}
        className={classNames}
        data-hook={dataHook}
        onKeyDown={this.handleWrapperKeyDown}
        style={{ height }}
        // need to hide wrapper from screen-reader when option selected,
        // dropdown closed or navigating by shift+tab
        aria-label={isPopoutHidden ? ariaLabel : null}
        aria-hidden={!isOpen && !isPopoutHidden ? true : null}
        role="listbox"
        tabIndex={tabIndex}
      >
        <div
          className={classnames(styles.label, labelClassName)}
          data-hook="dropdown-label"
          style={{ height }}
          onClick={this.toggle}
        >
          {this.renderSelectedValue()}
          <Icon
            getRef={memoizedPartial(this.saveRef, 'triangleRef')}
            className={classnames(styles['triangle-down'], triangleClassName)}
            name="triangle-down"
          />
        </div>

        <Popout
          getRef={memoizedPartial(this.saveRef, 'popoutRef')}
          className={optionsClassNames}
          innerClassName={styles['options-inner']}
          popoutSide={invertSide(popoutTriangleSide)}
          height={popoutHeight}
          isActive={isOpen && !isPopoutHidden}
          onClickOutside={this.close}
        >
          {this.renderOptions()}
        </Popout>
      </section>
    );
  }
}

export class WidgetDropdown extends React.Component {
  render() {
    const props = _.omit(this.props, 'styles');

    return (
      <BaseDropdown
        {...props}
        className={classnames(styles.widget, props.className)}
        ScrollbarWrapper={WidgetScrollbarWrapper}
      />
    );
  }
}

export class StripWidgetDropdown extends React.Component {
  render() {
    const props = _.omit(this.props, 'styles');

    return (
      <BaseDropdown
        {...props}
        className={classnames(styles.widget, styles.strip)}
        ScrollbarWrapper={WidgetScrollbarWrapper}
      />
    );
  }
}

export class BareDropdown extends React.Component {
  render() {
    const props = _.omit(this.props, 'styles');

    return (
      <BaseDropdown
        {...props}
        className={classnames(styles.bare, props.className)}
        enablePopoutPositioningUpdate
        popoutPosition={popoutPositions.CENTER}
        popoutTriangleSide={sides.TOP}
      />
    );
  }
}
