import React from 'react';
import { connect } from 'react-redux';

import { notForPreview } from '../../../utils/not-for-preview';
import { handleLogOutPaymentModal } from '../../../utils/handle-logout-payment-modal';

import PropTypes from 'prop-types';

import {
  openFullScreenPurchaseOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenRentOverlay,
} from '../../../utils/open-overlay';

const mapStateToProps = null;

const mapDispatchToProps = {
  openFullScreenPurchaseOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenRentOverlay,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class PlayerActionCallbacks extends React.Component {
    static propTypes = {
      children: PropTypes.element.isRequired,
      channelId: PropTypes.string,
      videoId: PropTypes.string,
    };

    componentDidMount() {
      handleLogOutPaymentModal({
        onRent: this.showRentInfoAtOverlay,
        onPurchase: this.showPurchaseInfoAtOverlay,
        onSubscription: this.showChannelSubscriptionInfoAtOverlay,
      });
    }

    showPurchaseInfoAtOverlay = notForPreview(() => {
      const { channelId, videoId } = this.props;
      this.props.openFullScreenPurchaseOverlay(channelId, videoId);
    });

    showRentInfoAtOverlay = notForPreview(() => {
      const { channelId, videoId } = this.props;
      this.props.openFullScreenRentOverlay(channelId, videoId);
    });

    showChannelSubscriptionInfoAtOverlay = notForPreview(() => {
      this.props.openFullScreenSubscribeOverlay(this.props.channelId);
    });

    render() {
      return React.cloneElement(this.props.children, {
        // TODO: rename to onPurchaseRequest and onSubscriptionRequest
        onPurchaseClick: this.showPurchaseInfoAtOverlay,
        onSubscribeClick: this.showChannelSubscriptionInfoAtOverlay,
        onRentClick: this.showRentInfoAtOverlay,
      });
    }
  },
);
