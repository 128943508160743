import { createLoggerActions } from '../../worker/actions/fedopsLogger';

export function createBaseMobileLogger(loggerName) {
  const { appLoaded, createInteraction } = createLoggerActions(loggerName);
  return {
    appLoaded,
    openSlideMenu: createInteraction('OPEN_SLIDE_MENU'),
    openVideoView: createInteraction('OPEN_VIDEO_VIEW'),
    openChannelView: createInteraction('OPEN_CHANNEL_VIEW'),
  };
}
