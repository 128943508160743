import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import { connect } from 'react-redux';
import fedopsLogger from '../../utils/fedops-logger';
import mobileFedopsLogger from '../../loggers/fedops/mobile-widget';

import { addSingleMeasurement } from '../../utils/performance';
import { getOpenedEventParams } from '../../utils/bi/video-overlay-events-params';
import { logBi } from '../../worker/actions/bi';
import { performanceApplicationLoaded } from '../../worker/actions/performanceLogger';
import PERFORMANCE_TYPES from '../../utils/performance/types';
import { CHANNEL_LAYOUT_VALUES } from '@wix/wix-vod-constants/dist/app-settings/channel-layout-values';
import { getAllSettings, getChannelLayout } from '../../selectors/app-settings';
import * as viewModeSelectors from '../../selectors/view-mode';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getMainVideo } from '../../selectors/get-video';
import { shouldShowChannelCover } from '@wix/wix-vod-shared/dist/src/common/utils/overlay-buttons-utils';
import { sendLoadComplete } from '../../utils/load-complete';
import { withWidgetProps } from '../widget-props';

const LAYOUTS_WITH_CHANNEL_COVER = [
  CHANNEL_LAYOUT_VALUES.CLASSIC,
  CHANNEL_LAYOUT_VALUES.COMPACT,
  CHANNEL_LAYOUT_VALUES.STRIP,
];

function performanceLogger(logger, showChannelCoverSelector) {
  const mapStateToProps = (state) => ({
    appSettings: getAllSettings(state),
    channel: getChannelForWidget(state),
    mainVideo: getMainVideo(state),
    isSiteMode: viewModeSelectors.isSiteMode(state),
    viewMode: viewModeSelectors.getViewMode(state),
    showChannelCover: showChannelCoverSelector(state),
  });

  const mapDispatchToProps = {
    logBi,
    sendLoadComplete,
    performanceApplicationLoaded,
  };

  return withWidgetProps((widgetProps) => ({
    registerToComponentDidLayout: widgetProps.host.registerToComponentDidLayout,
  }))(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(
      class PerformanceLoggers extends React.Component {
        static propTypes = {
          appSettings: PropTypes.object.isRequired,
          channel: PropTypes.object.isRequired,
          mainVideo: PropTypes.object,
          isSiteMode: PropTypes.bool.isRequired,
          viewMode: PropTypes.string.isRequired,
        };

        componentDidMount() {
          logger.appLoaded();
          this.logWidgetOpenedBi();
        }

        logWidgetOpenedBi = () => {
          const {
            appSettings,
            channel,
            mainVideo,
            isSiteMode,
            viewMode,
            showChannelCover,
          } = this.props;

          addSingleMeasurement(PERFORMANCE_TYPES.APP_FULLY_RENDERED);

          this.props.registerToComponentDidLayout(() => {
            this.props.performanceApplicationLoaded();
          });

          this.props.logBi(
            'widget.opened',
            getOpenedEventParams(
              appSettings,
              channel,
              mainVideo,
              showChannelCover,
              viewMode,
            ),
          );

          if (isSiteMode) {
            this.props.sendLoadComplete();
          }
        };

        render() {
          return null;
        }
      },
    ),
  );
}

function showChannelCoverForWidget(state) {
  const channelLayout = getChannelLayout(state);
  const canShowChannelCover = includes(
    LAYOUTS_WITH_CHANNEL_COVER,
    channelLayout,
  );
  const appSettings = getAllSettings(state);
  return shouldShowChannelCover(appSettings, canShowChannelCover);
}

export const WidgetPerformanceLoggers = performanceLogger(
  fedopsLogger,
  showChannelCoverForWidget,
);

export const MobilePerformanceLoggers = performanceLogger(
  mobileFedopsLogger,
  (state) => !state.isVideosTouched,
);
