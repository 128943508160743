import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { notForPreview } from '../../../../utils/not-for-preview';
import { handleLogOutPaymentModal } from '../../../../utils/handle-logout-payment-modal';

import { playVideo } from '../../../../redux/actions/player/change-playback-status';
import { selectVideo } from '../../../../redux/actions/select-video';
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from '../../../../redux/actions/full-screen-modal';
import {
  openFullScreenPurchaseOverlay,
  openFullScreenRentOverlay,
  openFullScreenMemberOnlyOverlay,
  openFullScreenSubscribeOverlay,
} from '../../../../utils/open-overlay';

const mapStateToProps = null;

const mapDispatchToProps = {
  playVideo,
  selectVideo,
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
  openFullScreenPurchaseOverlay,
  openFullScreenRentOverlay,
  openFullScreenMemberOnlyOverlay,
  openFullScreenSubscribeOverlay,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class InFrameActionCallbacks extends React.Component {
    static propTypes = {
      children: PropTypes.element.isRequired,
      selectVideo: PropTypes.func.isRequired,
      playVideo: PropTypes.func.isRequired,
      onBeforeClick: PropTypes.func,
      onBeforePlayRequested: PropTypes.func,
      onPlayRequestedBi: PropTypes.func,
      openFullScreenVideoOverlay: PropTypes.func,
      closeFullScreenVideoOverlay: PropTypes.func,
      channelId: PropTypes.string,
      videoId: PropTypes.string,
      videoItem: PropTypes.object,
    };

    static defaultProps = {
      onBeforeClick: _.noop,
      onBeforePlayRequested: _.noop,
      onPlayRequestedBi: _.noop,
    };

    componentDidMount() {
      handleLogOutPaymentModal({
        onRent: this.showVideoRentInfo,
        onPurchase: this.showVideoPurchaseInfo,
        onSubscription: this.showChannelSubscriptionInfoAtOverlay,
      });
    }

    selectVideo = () => {
      const { selectVideo, videoId } = this.props;
      selectVideo(videoId);
    };

    playVideo() {
      const { playVideo, videoId } = this.props;
      playVideo(videoId);
    }

    showVideoAtOverlay = notForPreview(() => {
      const {
        channelId,
        videoId,
        openFullScreenVideoOverlay,
        closeFullScreenVideoOverlay,
      } = this.props;
      openFullScreenVideoOverlay(
        channelId,
        videoId,
        null,
        closeFullScreenVideoOverlay,
      );
    });

    showVideoMemberOnlyInfo = notForPreview(() => {
      const { channelId, videoId } = this.props;
      this.props.openFullScreenMemberOnlyOverlay(channelId, videoId);
    });

    showVideoPurchaseInfo = notForPreview(() => {
      const { channelId, videoId } = this.props;
      this.props.openFullScreenPurchaseOverlay(channelId, videoId);
    });

    showVideoRentInfo = notForPreview(() => {
      const { channelId, videoId } = this.props;
      this.props.openFullScreenRentOverlay(channelId, videoId);
    });

    showChannelSubscriptionInfoAtOverlay = notForPreview(() => {
      this.props.openFullScreenSubscribeOverlay(this.props.channelId);
    });

    handleSelectVideo = () => {
      this.props.onBeforeClick();
      this.selectVideo();
    };

    handlePlayVideo = () => {
      this.props.onBeforePlayRequested();
      this.props.onPlayRequestedBi();
      this.playVideo();
    };

    render() {
      return React.cloneElement(this.props.children, {
        onClick: this.handleSelectVideo,
        onPlayRequest: this.handlePlayVideo,
        onPlayMemberOnlyRequest: this.showVideoMemberOnlyInfo,
        onPlayWithoutPreviewRequest: this.handleSelectVideo,
        onPurchaseRequest: this.showVideoPurchaseInfo,
        onRentRequest: this.showVideoRentInfo,
        onSubscriptionRequest: this.showChannelSubscriptionInfoAtOverlay,
      });
    }
  },
);
