import { createLoggerActions } from '../../worker/actions/fedopsLogger';
import { VIDEO_SOURCES } from '@wix/wix-vod-constants/dist/common/video-sources';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import LAYOUTS_MAP from '@wix/wix-vod-shared/dist/src/common/layouts-map';

const SOURCES_INTERACTIONS_MAPPING = {
  [VIDEO_SOURCES.COMPUTER]: 'VIDEO_PLAY_COMPUTER',
  [VIDEO_SOURCES.YOUTUBE]: 'VIDEO_PLAY_YOUTUBE',
  [VIDEO_SOURCES.VIMEO]: 'VIDEO_PLAY_VIMEO',
  [VIDEO_SOURCES.FACEBOOK]: 'VIDEO_PLAY_FACEBOOK',
  [VIDEO_SOURCES.LIVE]: 'VIDEO_PLAY_LIVE',
  // VIDEO_SOURCES.MIXED,
  // VIDEO_SOURCES.DROPBOX,
  // VIDEO_SOURCES.GOOGLE_DRIVE,
};

const noopActionCreator = () => () => {};

const noopInteraction = { start: noopActionCreator, end: noopActionCreator };

function createPlayVideoInteraction(createInteraction) {
  return ({ videoSource }) => {
    const interactionName = SOURCES_INTERACTIONS_MAPPING[videoSource];
    if (interactionName === undefined) {
      return noopInteraction;
    }

    return createInteraction(interactionName);
  };
}

function createPlayInteraction(createInteraction) {
  const getInteraction = (layout, videoItem) => {
    const videoSource = isLiveVideo(videoItem)
      ? VIDEO_SOURCES.LIVE
      : videoItem.videoSource;
    const interactionName = `PLAY_${videoSource.toUpperCase()}_${LAYOUTS_MAP[
      layout
    ].toUpperCase()}`;
    return createInteraction(interactionName);
  };

  return {
    start: (layout, videoItem) => getInteraction(layout, videoItem).start(),
    end: (layout, videoItem) => getInteraction(layout, videoItem).end(),
  };
}
function createNewFedopsLogger() {
  const { appLoaded, createInteraction } = createLoggerActions(undefined);
  return {
    appLoaded,
    getWidgetData: createInteraction('GET_WIDGET_DATA'),
    playVideo: createPlayVideoInteraction(createInteraction),
    autoplayLive: createInteraction('AUTOPLAY_LIVE'),
    play: createPlayInteraction(createInteraction),
    loadCashierModal: createInteraction('CASHIER_MODAL_LOAD'),
    loadCashierPaymentPage: createInteraction('CASHIER_PAYMENT_PAGE_LOAD'),
    loadCashierPaymentCompletePage: createInteraction(
      'CASHIER_PAYMENT_COMPLETE_PAGE_LOAD',
    ),
  };
}

export default createNewFedopsLogger();
