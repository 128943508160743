import fedopsLogger from '../loggers/fedops/new-fedops-logger';

import {
  openFullScreenPurchaseOverlay as openPurchaseOverlay,
  openFullScreenMemberOnlyOverlay as openMemberOnlyOverlay,
  openFullScreenSubscribeOverlay as openSubscribeOverlay,
  openFullScreenRentOverlay as openRentOverlay,
} from './open-overlay-base';
import { createProxy } from '../worker/lib';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';

export const openFullScreenPurchaseOverlay = createProxy(
  'openFullScreenPurchaseOverlay',
  (...args) =>
    (dispatch) => {
      dispatch(fedopsLogger.loadCashierModal.start());
      dispatch(openPurchaseOverlay(...args));
    },
);

export const openFullScreenMemberOnlyOverlay = createProxy(
  'openFullScreenMemberOnlyOverlay',
  (...args) =>
    (dispatch) => {
      dispatch(fedopsLogger.loadCashierModal.start());
      dispatch(openMemberOnlyOverlay(...args));
    },
);

export const openFullScreenSubscribeOverlay = createProxy(
  'openFullScreenSubscribeOverlay',
  (channelId, callback) => (dispatch, getState) => {
    const {
      channelInfo: { info },
    } = getState();

    const isPlan = isPricingPlanEnabled(info);

    dispatch(fedopsLogger.loadCashierModal.start());
    dispatch(openSubscribeOverlay(channelId, callback, isPlan));
  },
);

export const openFullScreenRentOverlay = createProxy(
  'openFullScreenRentOverlay',
  (...args) =>
    (dispatch) => {
      dispatch(fedopsLogger.loadCashierModal.start());
      dispatch(openRentOverlay(...args));
    },
);
