import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SlideMenuIcon from '../../../../assets/top-menu.svg';
import {
  openSlideMenu,
  closeSlideMenu,
} from '../../../../redux/actions/slide-menu';
import { shouldShowMenuButton } from '../../ui-selectors/slide-menu';
import fedopsLogger from '../../../../../loggers/fedops/mobile-widget';

import { withTranslation } from '@wix/yoshi-flow-editor';

const mapStateToProps = (state, props) => ({
  isSlideMenuOpened: state.slideMenuOpened,
  shouldBeShown: props.alwaysShow || shouldShowMenuButton(state, props),
});

const mapDispatchToProps = {
  openSlideMenu,
  closeSlideMenu,
  logOpenSlideMenuStart: fedopsLogger.openSlideMenu.start,
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class SlideMenuButton extends React.Component {
      static propTypes = {
        videoItem: PropTypes.object,
        channelData: PropTypes.object,

        className: PropTypes.string,
        iconClassName: PropTypes.string,

        isSlideMenuOpened: PropTypes.bool,
        alwaysShow: PropTypes.bool,
        shouldBeShown: PropTypes.bool,
        isFirstVideo: PropTypes.bool,

        openSlideMenu: PropTypes.func,
        closeSlideMenu: PropTypes.func,
      };

      handleClick = () => {
        const { isSlideMenuOpened, openSlideMenu, closeSlideMenu } = this.props;

        if (isSlideMenuOpened) {
          closeSlideMenu();
        } else {
          this.props.logOpenSlideMenuStart();
          openSlideMenu();
        }
      };

      render() {
        const { className, iconClassName, shouldBeShown } = this.props;

        return (
          shouldBeShown && (
            <div
              data-hook="slide-menu-button"
              className={className}
              onClick={this.handleClick}
              role="button"
              aria-label={this.props.t(
                'widget.mobile.slide-menu-button-accessibility',
              )}
            >
              <SlideMenuIcon className={iconClassName} />
            </div>
          )
        );
      }
    },
  ),
);
