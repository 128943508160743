import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WindowSize from '../../../containers/window-size';
import PaymentEventProxy from '../../../components/payment-events/proxy';
import HomeView from '../../views/home/home';
import HomeWrapper from './wrapper/home.wrapper';

import { MobileModal } from '../../components/modal/modal';
import MobileSpinnerOverlay from '../../components/mobile-spinner-overlay/mobile-spinner-overlay';

import { isPlayingOptimistic } from '../../../selectors/playback';
import { requestPause } from '../../../redux/reducers/playback';

import BiHandler from '../../../bi/bi-handler';
import ReloadChannelPaymentDataOnPayment from '../../../data-components/reload-channel-payment-data-on-payment';
import ClearVideoUrlsCacheOnPayment from '../../../data-components/clear-video-urls-cache-on-payment';
import PaymentsBiHandler from '../../../components/payments/bi-handler';

import { getViewMode } from '../../../selectors/view-mode';

import '../../../styles/core.scss';

import EditorObserver from './editor-observer';
import WidgetHeightByContentSync from './widget-height-by-content-sync';
import { ViewerObserver } from '../../../components/viewer-observer/viewer-observer';
import { AppSettingsObserver } from '../../../data-components/app-settings-loader/app-settings-loader';
import { LoginWatcher } from '../../../containers/login-watcher';

const mapStateToProps = (state) => ({
  viewMode: getViewMode(state),
  selectedVideoId: state.selectedVideoId,
  windowSize: state.windowSize,
  isVideoPlaying: isPlayingOptimistic(state, state.selectedVideoId),
});

const mapDispatchToProps = {
  requestPause,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class HomeLayout extends React.Component {
    static propTypes = {
      selectedVideoId: PropTypes.string,
      viewMode: PropTypes.string,
      windowSize: PropTypes.object.isRequired,
      isVideoPlaying: PropTypes.bool,
      requestPause: PropTypes.func,
    };

    pauseVideo = () => {
      const { requestPause, selectedVideoId } = this.props;
      requestPause(selectedVideoId);
    };

    renderView() {
      return (
        <React.Fragment>
          <WidgetHeightByContentSync>
            <HomeView />
          </WidgetHeightByContentSync>
          <BiHandler />
          <PaymentsBiHandler />
          <PaymentEventProxy />
          <AppSettingsObserver />
          <ReloadChannelPaymentDataOnPayment />
          <ClearVideoUrlsCacheOnPayment />
          <LoginWatcher />
          <MobileModal />
          <EditorObserver />
          <ViewerObserver
            pauseVideo={this.pauseVideo}
            isVideoPlaying={this.props.isVideoPlaying}
          />
        </React.Fragment>
      );
    }

    renderSpinner() {
      return <MobileSpinnerOverlay />;
    }

    render() {
      const { viewMode, windowSize } = this.props;

      const shouldRenderView = windowSize.width;

      return (
        <WindowSize>
          {shouldRenderView ? (
            <HomeWrapper viewMode={viewMode}>{this.renderView()}</HomeWrapper>
          ) : (
            this.renderSpinner()
          )}
        </WindowSize>
      );
    }
  },
);
